<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogComercial.estatus"
    max-width="800"
  >
    <v-card>
    	<v-card-title class="text-headline">
    		Desglose informativo Comercial
    	</v-card-title>
    	<v-card-text class="black--text">
    		Formula
    		<br/>
    		<br/>
    		VENTAS NUEVAS TOTALES POR ESCUELA
				<br/>
				<b>Inscritos FAST:</b> {{ totalFastM.inscritos }}
				<br/>
				<b>Inscritos INBI:</b> {{ totalInbiM.inscritos }}

				<v-divider class="mt-6"></v-divider>
				<label>KPI auxiliares</label>

				<v-row>
      		<v-col cols="12">
            <v-row>
            	<v-col cols="12" md="6" >
            		<v-card color="shadowCard text-center">
            		  <v-card-title primary-title>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="community.contactos_INBI >= community.aceptable ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ community.contactos_INBI }}
            		    	</v-card>
            		    	Inbi
            		    </div>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="community.contactos_FAST >= community.aceptable ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ community.contactos_FAST }}
            		    	</v-card>
            		    	Fast
            		    </div>
            		  	<v-spacer></v-spacer>
            		  </v-card-title>

            		  <v-card-text>
            		    Valor aceptable: {{ community.aceptable }}
            		  </v-card-text>
            		  
            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
            		    Community
            		  </v-card-text>
            		  <v-card-text class="black--text pt-0">
            		  	#Contactos
            		  </v-card-text>
            		</v-card>
            	</v-col>

            	<v-col cols="12" md="6" >
            		<v-card color="shadowCard text-center">
            		  <v-card-title primary-title>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="community.avanceINBI >= 95 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ community.avanceINBI }} <span class="text-capitalize text-h6">%</span>
            		    	</v-card>
            		    	Inbi
            		    </div>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="community.avanceFAST >= 95 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ community.avanceFAST }} <span class="text-capitalize text-h6">%</span>
            		    	</v-card>
            		    	Fast
            		    </div>
            		  	<v-spacer></v-spacer>
            		  </v-card-title>

            		  <v-card-text>
            		    Valor aceptable: 95%
            		  </v-card-text>
            		  
            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
            		    Community
            		  </v-card-text>
            		  <v-card-text class="black--text pt-0">
            		  	% de conversión
            		  	<br/>
            		  	% de conversión = (Total de conversiones / Total de leads generados ) × 100%
                    <br/>
                    <br/>
                    % de conversión = ( {{ community.contactos_INBI }} / {{ community.contactos_INBI_prospeto }} ) × 100%
                    <br/>
                    <br/>
                    % de conversión = ( {{ community.contactos_FAST }} / {{ community.contactos_FAST_prospeto }} ) × 100%

            		  </v-card-text>
            		</v-card>
            	</v-col>
            </v-row>
      		</v-col>
      	</v-row>
    	</v-card-text>
    	<v-card-actions>
		    <v-btn 
		    	color="black"
		    	dark
		    	tile
		    	@click="dialogComercial.estatus = false"
		    >
		    	<v-icon left small>mdi-cancel</v-icon>
		  		Cerrar
		  	</v-btn>
    	</v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
	import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  import XLSX from 'xlsx'
	export default {
		props:[
			'dialogComercial',
			'rh',
			'totalFastM',
			'totalInbiM',
			'cicloInf',
			'fechaini',
			'fechafin'
	  ],

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			community:{},

    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    async created () {
      await this.initialize4()
    },

    methods: {

    	getCiclos () {
        this.cargar    = true
        this.ciclos    = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	async initialize4(){
    		await this.initialize()
    	},

    	initialize () {
        this.cargar    = true
        var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('kpi.departamento.auxiliar', payload ).then(response=>{

          this.soporte   = response.data.soporte 
          this.community = response.data.community 
          this.finanzas  = response.data.finanzas 

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      getNI(){
      	var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('nuevas.matriculas',payload).then(response=>{
        	console.log( response.data.merca)
          // Guardar los datos 
					this.totalFast        = response.data.totalFast 
					this.totalInbi        = response.data.totalInbi
					this.totalFastM       = response.data.merca.find( el => el.escuela == 2 )
					this.totalInbiM       = response.data.merca.find( el => el.escuela == 1 )

          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },


      abrirDialog( value ){
        this.alumnos = value
        this.dialogAlumnos = true
      },

      exportar(){
        this.exportarData()
      },

      s2ab2(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

    },
  }
</script>