<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogFinanzas.estatus"
    max-width="800"
  >
    <v-card>
    	<v-card-title class="text-headline">
    		Desglose informativo Finanzas
    	</v-card-title>
    	<v-card-text class="black--text">
    		Formula
    		<br/>
    		<br/>
    		ÍNDICE DE EFICIENCIA FINANCIERA
				<br/>
				<b>Cumplió:</b> {{ finanzas.cumplio >= 1 ? 'SI' : 'NO' }}

				<v-divider class="mt-6"></v-divider>
				<label>KPI auxiliares</label>
				<v-row>
      		<v-col cols="12">
            <v-row>
            	<v-col cols="12" md="6">
            		<v-card color="shadowCard text-center">
            		  <v-card-title primary-title>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="auxiliares.avanceIngresos >= 100 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ auxiliares.avanceIngresos }} %
            		    	</v-card>
            		    </div>
            		  	<v-spacer></v-spacer>
            		  </v-card-title>

            		  <v-card-text>
            		    Valor aceptable: 100%
            		  </v-card-text>
            		  
            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
            		    Analista de ingresos
            		  </v-card-text>
            		  <v-card-text class="black--text pt-0">
            		  	% VALIDACIÓN DE INGRESOS
            		  </v-card-text>

                  <v-card-text class="text-left">
                    <b>% Egreseos aceptados</b> = ( Cantidad de egresos aceptados / Cantidad de egresos totales ) × 100
                    <br/>
                    <br/>
                    <b>% Egreseos aceptados</b> = ( {{ auxiliares.ingresosValidos }} / {{ auxiliares.totalIngresos }} ) × 100
                  </v-card-text>

            		  <v-btn 
            		  	color="blue"
            		  	absolute
            		  	top
            		  	right
            		  	icon
            		  >
            				<v-icon>mdi-information-outline</v-icon>
            			</v-btn>
            		</v-card>
            	</v-col>

            	<v-col cols="12" md="6">
            		<v-card color="shadowCard text-center">
            		  <v-card-title primary-title>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="avanceEgresos >= 100 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ avanceEgresos }} %
            		    	</v-card>
            		    </div>
            		  	<v-spacer></v-spacer>
            		  </v-card-title>

            		  <v-card-text>
            		    Valor aceptable: 100%
            		  </v-card-text>
            		  
            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
            		    Analista de egresos
            		  </v-card-text>
            		  <v-card-text class="black--text pt-0">
            		  	% VALIDACIÓN DE EGRESOS
            		  </v-card-text>

                  <v-card-text class="text-left">
                    <b>% Ingresos validados</b> = ( Cantidad de ingresos validados / Cantidad de ingresos totales ) × 100
                    <br/>
                    <br/>
                    <b>% Ingresos validados</b> = ( {{ totalAutorizado }} / {{ cantidadTotal }} ) × 100
                  </v-card-text>

            		  <v-btn 
            		  	color="blue"
            		  	absolute
            		  	top
            		  	right
            		  	icon
            		  >
            				<v-icon>mdi-information-outline</v-icon>
            			</v-btn>
            		</v-card>
            	</v-col>
            </v-row>
      		</v-col>
      	</v-row>
    	</v-card-text>
    	<v-card-actions>
		    <v-btn 
		    	color="black"
		    	dark
		    	tile
		    	@click="dialogFinanzas.estatus = false"
		    >
		    	<v-icon left small>mdi-cancel</v-icon>
		  		Cerrar
		  	</v-btn>
    	</v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

  </v-dialog>
</template>


<script>
	import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  import XLSX from 'xlsx'
	export default {
		props:[
			'dialogFinanzas',
			'rh',
			'finanzas',
			'cicloInf',
			'fechaini',
			'fechafin'
	  ],

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			community:{},

			auxiliares:{},
			nomina:[]

    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      totalAutorizado() {
	      return this.nomina.filter( el => el.estatus == "Autorizado").length
	    },

	    cantidadTotal() {
	      let subtotal = this.nomina.length;
	      return Math.floor(subtotal);
	    },

	    avanceEgresos( ){
	    	let avance = this.totalAutorizado ? ((this.totalAutorizado / this.cantidadTotal)*100) : 0 
	    	return avance
	    }
    },

    async created () {
      await this.initialize4()
      await this.initialize2()
    },

    methods: {

    	getCiclos () {
        this.cargar    = true
        this.ciclos    = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	async initialize4(){
    		await this.initialize()
    	},

    	initialize () {
        this.cargar    = true
        var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('kpi.departamento.auxiliar', payload ).then(response=>{

          this.auxiliares = response.data.finanzas 

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize2() {
	      this.nomina = [];
	      this.cargar = true;

	      const payload = {
	        fecha_inicio: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
	        fecha_final:  this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
	        ciclo: null,
	      };

	      return this.$http.post("consultar.partidas.egreso", payload).then((response) => {
          this.nomina = response.data;
          console.log(this.nomina )
          this.cargar = false;

        })
        .catch((error) => {
          this.validarError(error);
        })
        .finally(() => {
          this.cargar = false;
        });
	    },


      getNI(){
      	var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo : this.fechafin, 
        }

        this.$http.post('nuevas.matriculas',payload).then(response=>{
        	console.log( response.data.merca)
          // Guardar los datos 
					this.totalFast        = response.data.totalFast 
					this.totalInbi        = response.data.totalInbi
					this.totalFastM       = response.data.merca.find( el => el.escuela == 2 )
					this.totalInbiM       = response.data.merca.find( el => el.escuela == 1 )

          this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },


      abrirDialog( value ){
        this.alumnos = value
        this.dialogAlumnos = true
      },

      exportar(){
        this.exportarData()
      },

      s2ab2(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

    },
  }
</script>