<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogRh.estatus"
    max-width="800"
  >
    <v-card>
    	<v-card-title class="text-headline">
    		Desglose informativo Recursos humanos
    	</v-card-title>
    	<v-card-text class="black--text">
    		Formula de rotación
    		<br/>
    		% de rotación = (# de empleados que dejaron la empresa / # total de empleados al inicio del periodo) × 100
				<br/>
				% de rotación = ( {{ rh.bajas }} / {{ rh.activos }}) × 100 | {{ rh.rotacion }}
        <br/>
        <br/>
        Formula de Vacantes cubiertas
        <br/>
        % de vacantes cubiertas = ( Número de vacantes cubiertas / Número total de vacantes ) * 100
        <br/>
        % de vacantes cubiertas = ( {{ rhAuxi.total }} / {{ rhAuxi.cubiertas }}) × 100 

				<v-divider class="mt-6"></v-divider>
				<label>KPI auxiliares</label>

				<v-row>
      		<v-col cols="12">
            <v-row>
            	<v-col cols="12" md="6">
            		<v-card color="shadowCard text-center">
            		  <v-card-title primary-title>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="rhAuxi.promediopersonal <= 6 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ rhAuxi.promediopersonal }}
            		    	</v-card>
            		    </div>
            		  	<v-spacer></v-spacer>
            		  </v-card-title>

            		  <v-card-text>
            		    Valor aceptable: 6 días
            		  </v-card-text>
            		  
            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
            		    RECLUTADOR
            		  </v-card-text>
            		  <v-card-text class="black--text pt-0">
            		  	TIEMPO DE CONTRATACIÓN
            		  </v-card-text>
                  
                  <v-card-text class="text-left">
                    <b>Tiempo de contratación = fecha de alta de vacante - fecha de contratación</b>
                    <br/>
                    <br/>
                    <b>Promedio de tiempo de contratación</b> = Suma de tiempo de contratación de todas las vacantes / número de vacantes totales
                    <br/>
                    <br/>
                    <b>Promedio de tiempo de contratación</b> = {{ rhAuxi.sumatiempo }} / {{ rhAuxi.cantpersonal }}
                  </v-card-text>
            		</v-card>
            	</v-col>

            	<v-col cols="12" md="6">
            		<v-card color="shadowCard text-center">
            		  <v-card-title primary-title>
            		  	<v-spacer></v-spacer>
            		    <div> 
            		    	<v-card :color="rhAuxi.promediopersonalvacante <= 6 ? 'green' : 'red darken-2'" dark class="pa-4 text-center text-h3 shadowCard">
            		    		{{ rhAuxi.promediopersonalvacante }}
            		    	</v-card>
            		    </div>
            		  	<v-spacer></v-spacer>
            		  </v-card-title>

            		  <v-card-text>
            		    Valor aceptable: 6 días
            		  </v-card-text>
            		  
            		  <v-card-text class="black--text text-h6 pt-0 pb-1">
            		    RECLUTADOR
            		  </v-card-text>
            		  <v-card-text class="black--text pt-0">
            		  	TIEMPO DE VACANTE ABIERTA
            		  </v-card-text>

                  <v-card-text class="text-left">
                    <b>Tiempo de vacante abierta</b>
                    <br/>
                    <br/>
                    <b>Promedio de tiempo de vacante abierta </b>= Suma de tiempo de vacante abierta / número de vacantes totales
                    <br/>
                    <br/>
                    <b>Promedio de tiempo de contratación</b> = {{ rhAuxi.sumatiempovacante }} / {{ rhAuxi.cantpersonalvacante }}

                  </v-card-text>
            		</v-card>
            	</v-col>
            </v-row>
      		</v-col>
      	</v-row>

    	</v-card-text>
    	<v-card-actions>
		    <v-btn 
		    	color="black"
		    	dark
		    	tile
		    	@click="dialogRh.estatus = false"
		    >
		    	<v-icon left small>mdi-cancel</v-icon>
		  		Cerrar
		  	</v-btn>
    	</v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta v-if="respuestaAlerta" :parametros="parametros" />
    <carga v-if="cargar" />

  </v-dialog>
</template>


<script>
	import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  import XLSX from 'xlsx'
  
	export default {
		props:[
			'dialogRh',
			'rh',
			'finanzas',
			'cicloInf',
			'fechaini',
			'fechafin'
	  ],

	  components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

			community:{},

			auxiliares:{},
      rhAuxi:{},
			nomina:[]

    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      totalAutorizado() {
	      return this.nomina.filter( el => el.estatus == "Autorizado").length
	    },

	    cantidadTotal() {
	      let subtotal = this.nomina.length;
	      return Math.floor(subtotal);
	    },

	    avanceEgresos( ){
	    	let avance = this.totalAutorizado ? ((this.totalAutorizado / this.cantidadTotal)*100) : 0 
	    	return avance
	    }
    },

    async created () {
      await this.initialize()
    },

    methods: {

    	initialize () {
        this.cargar    = true
        var payload = { 
        	fechaini: this.cicloInf ? this.cicloInf.fecha_inicio_ciclo : this.fechaini, 
        	fechafin: this.cicloInf ? this.cicloInf.fecha_fin_ciclo    : this.fechafin, 
        }

        this.$http.post('kpi.departamento.auxiliar', payload ).then(response=>{

          this.rhAuxi = response.data.rh 

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirDialog( value ){
        this.alumnos = value
        this.dialogAlumnos = true
      },

      exportar(){
        this.exportarData()
      },

      s2ab2(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

    },
  }
</script>